import Head from 'next/head';
import { NextPage } from 'next';
import { Layout } from 'antd';
import Home from '../containers/Home';
import Toolbar from '../components/Toolbar';

const { Content, Footer } = Layout;

const Index: NextPage = () => {
  return (
    <Layout>
      <style jsx>
        {`
          @media only screen and (min-width: 320px) and (max-width: 767.9999px) {
            #container {
              padding: 32px 12px;
            }
          }

          @media only screen and (min-width: 768px) and (max-width: 1365.9999px) {
            #container {
              padding: 32px 80px;
            }
          }

          @media only screen and (min-width: 1366px) {
            #container {
              padding: 32px 160px;
            }
          }
        `}
      </style>
      <Head>
        <title>Template | Prepare Software</title>
      </Head>
      <Toolbar />
      <Content>
        <div id="container">
          <Home />
        </div>
      </Content>
      <Footer>Copyright © 2020 Prepare Software, LLC. </Footer>
    </Layout>
  );
};

export default Index;
