import { NextPage } from 'next';
import { Suspense } from 'react';
import Visitors from '../components/Visitors';
import CheckIn from '../components/CheckIn';
import Loading from '../components/Loading';

const isServer = typeof window === 'undefined';

const Home: NextPage = () => {
  return (
    <>
      <CheckIn />
      {!isServer ? (
        <>
          <Suspense fallback={<Loading />}>
            <Visitors />
          </Suspense>
        </>
      ) : null}
    </>
  );
};

export default Home;
