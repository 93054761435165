import { Button, PageHeader, Tag } from 'antd';
import { signIn, signOut, useSession } from 'next-auth/client';

const Toolbar = (): JSX.Element => {
  const [session] = useSession();

  const status = session ? (
    <Tag color="blue">{session.user.email}</Tag>
  ) : (
    <Tag color="red">Anonymous</Tag>
  );
  return (
    <>
      <PageHeader
        ghost={false}
        title="Template"
        tags={status}
        extra={
          session
            ? [
                <Button key="1" onClick={() => signOut()}>
                  Sign Out
                </Button>,
              ]
            : [
                <Button key="2" onClick={() => signIn()}>
                  Sign In
                </Button>,
              ]
        }
      />
    </>
  );
};

export default Toolbar;
