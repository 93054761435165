import { Card, Button, Divider, Descriptions, message } from 'antd';

import { ReloadOutlined, BugTwoTone } from '@ant-design/icons';

import { useState } from 'react';

type StatusMessage = {
  status: string;
};
const HTTPStatusCode = (props: StatusMessage): JSX.Element => {
  const { status } = props;

  const [loading, setLoading] = useState(false);

  function retry() {
    setLoading(true);
    const timer = setTimeout(() => {
      message.error('Failed to reload');
      setLoading(false);
    }, 1500);
    return () => clearTimeout(timer);
  }
  return (
    <>
      <Card
        bordered={false}
        bodyStyle={{
          backgroundColor: '#d9d9d9',
          padding: '24px',
          margin: '0px',
        }}
      >
        <Descriptions
          title={
            <>
              There was an error <BugTwoTone />
            </>
          }
        >
          <Descriptions.Item label="Response">{status}</Descriptions.Item>
        </Descriptions>
        <Divider orientation="center">
          <Button
            loading={loading}
            icon={<ReloadOutlined />}
            onClick={() => retry()}
          >
            Reload
          </Button>
        </Divider>
      </Card>
    </>
  );
};

export default HTTPStatusCode;
