import { Button, Divider, message, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';
import Emoji from 'a11y-react-emoji';
import postOptions from '../lib/fetch';

const { Title, Text } = Typography;

const CheckIn = (): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const submitVisit: any = () => {
    setLoading(true);
    fetch('/api/visits', postOptions)
      .then((res: Response) => {
        switch (res.status) {
          case 200:
            message.success('Visit logged successfully');
            break;
          case 500:
            message.error(res.statusText);
            break;
          default:
            break;
        }
      })
      .catch((err: Error) => {
        message.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <Title level={3}>
        <Emoji symbol="🧑‍🤝‍🧑" />
        Visitor Check-in
      </Title>
      <Text>
        This is a demo of a privacy preserving visitor check-in form. This demo
        makes use of the SWR data fetching pattern to present a real-time list
        of the ten most recently recorded visits to this website.
      </Text>
      <Divider />
      <Title level={4}>
        <Emoji symbol="🍔" />
        Technologies Used
      </Title>
      <Title level={5}>
        <Emoji symbol="🤖" />
        Server-side - Prisma, PostgreSQL, and serverless
      </Title>
      <Title level={5}>
        <Emoji symbol="🧍" />
        Client-side - React Suspense, Hooks, and SWR
      </Title>
      <Divider />
      <Title level={4}>
        <Emoji symbol="📡" />
        Privacy
      </Title>
      <Text>
        Only users who click the button below will be logged to the visitor
        check-in form. Logs contain your IP address and the time of the visit.
      </Text>

      <Divider orientation="left">
        <Button
          type="primary"
          icon={<PlusOutlined />}
          loading={loading}
          onClick={() => submitVisit()}
        >
          Log Visit
        </Button>
      </Divider>
    </div>
  );
};

export default CheckIn;
