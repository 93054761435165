import useSWR from 'swr';
import { Visit } from '@prisma/client';
import { List, Typography } from 'antd';
import HTTPStatusCode from './HTTPStatusCode';

const { Text } = Typography;

const Visitors = (): JSX.Element => {
  const { data, error } = useSWR('/api/visits', {
    refreshInterval: 1000,
  });
  if (error || !data) data.error = 'Failed to fetch request';
  if (data.error) return <HTTPStatusCode status={data.error} />;

  return (
    <>
      <List
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(visit: Visit) => (
          <List.Item>
            <List.Item.Meta
              title={<Text>{`🕑 ${visit.createdAt} EST`}</Text>}
              description={<Text>{`🌐 ${visit.ipAddress}`}</Text>}
            />
          </List.Item>
        )}
      />
    </>
  );
};

export default Visitors;
